import React, { useState } from 'react';

import { useParams, useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie';
import Header from './../components/Header.jsx';

import addToWalletImg from '../img/add-to-apple-wallet.svg';

function Pass() {
  const [cookies,
    //setCookie,
    removeCookie
    ] = useCookies(['user_id']);
  const navigate = useNavigate();
  const { userId } = useParams();
  // let user = null;
  const user_id = userId || cookies.user_id

  const [isMaximized, setIsMaximized] = useState(false)

  // const publicURL = `${process.env.PUBLIC_URL}/pass`

  if ( "undefined" === user_id || !user_id) window.location='/';
  // const { user } = state

  const passImgSrc = `https://s3.amazonaws.com/pringlespass.com/passes/${user_id}.png`

  const download = async (blob, fileName) => {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0)
  }

  function iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  const isIOS = iOS()

  async function downloadIt() {
    const blob = await fetch(passImgSrc).then(res => res.blob());
    await download(blob, `PringlesPass.png`);
  }

  const appleWalletURL = `${process.env.REACT_APP_API_URL}/wallet/apple/${user_id}`
  async function addToWallet() {
    const blob = await fetch(appleWalletURL).then(res => res.blob());
    await download(blob, `pass.pkpass`);
  }

  const actions = (<button onClick={takeQuizAgain} className="button grey-button">
      Not you?
    </button>);

  function toggleMaximize() {
    setIsMaximized(!isMaximized)
  }

  const imgTag = [<img
    key="pass-img"
    onClick={ toggleMaximize }
    className={`result-img ${isMaximized ? 'is-maximized' : ''}`}
    alt="Pringles Pass"
    src={ passImgSrc } />,
    (isMaximized ? <div key="result-img-screen" className="result-img-screen"></div> : null)
  ]

  const addActions = [
    isIOS ? (
      <a href={`${appleWalletURL}?token=${process.env.REACT_APP_CAMPAIGN_TOKEN}`}
        onClick={addToWallet}
        key="add-to-apple"
        className="add-to-apple">
        <img src={addToWalletImg} alt="Add to Apple Wallet" />
      </a>) : null,

      <button
        key="save-pass"
        onClick={downloadIt}
        className="button blue-button share">
        Save Pass
      </button>
  ]

  function takeQuizAgain() {
    removeCookie('user_id')
    return navigate("/", { state: { } })
  }

  return (
    <>
      <Header className="page-splash" />

      <div id="app" className="App-template-wrapper">
        <div className="Results-wrapper">
          <div className="Results-inner-wrapper Original-wrapper">
            <div className="Results-text-wrapper">
              <div className="loading-characters characters-results">
                <h1 className="title orange small">
                  Pringles Playground All Access
                </h1>
                <p>
                  Use this QR code to play the Get Stuck In and Win game or create your own Pringles Music Mix inside the giant Pringles can.
                </p>
              </div>

              { imgTag }

              <div className="add-actions">
                {addActions}
              </div>
            </div>
          </div>
        </div>

        <div className="App-content-outer-container">
          <div className="App-content-inner-container">
            { actions }
          </div>
        </div>
      </div>
    </>
  );
}

export default Pass;
