import * as React from "react";
import { Routes, Route, Link } from "react-router-dom";

import Form from './pages/Form';
import Pass from './pages/Pass';

function Router() {
  return (
    <Routes>
      <Route index element={<Form />} />
      <Route path="/pass/:userId" element={<Pass />} />
      <Route path="/404" element={<NoMatch />} />
      <Route path="*" element={<NoMatch />} />
    </Routes>
 );
}

function NoMatch() {
  return (
    <div className="four-o-four">
      <h1 className="title">Nothing to see here!</h1>
      <p>
        <Link to="/" className="button">Go to the home page</Link>
      </p>
    </div>
  );
}

export default Router;
