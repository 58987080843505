import React from "react";

export default function Header(props) {
  return (
    <header className={`header ${props.className}`}>
      <svg overflow="auto" viewBox="0 44 3023.52 1583.45">
        <use href="#svg-banner"></use>
      </svg>
    </header>
  );
}
